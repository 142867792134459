// Here you can add other styles

.hidden {
  display: none;
}

.bg-magenta{
  background-color: #A9238E;
}
.bg-blue{
  background-color: #273AE3;
}
.bg-gray{
  background-color: #666;
}
.bt-gray{
  background-color: #666;
  outline-color : #666;
}
.icon-gray{
  color: #666666;
}
.btn-gray {
  --cui-btn-color: #fff;
  --cui-btn-bg: #666666;
  --cui-btn-border-color: #666666;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #666666;
  --cui-btn-hover-border-color: #666666;
  --cui-btn-focus-shadow-rgb: 113,111,220;
  --cui-btn-active-color: #666666;
  --cui-btn-active-bg: #666666;
  --cui-btn-active-border-color: #666666;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(8,10,12,.125);
  --cui-btn-disabled-color: #fff;
  --cui-btn-disabled-bg: #5856d6;
  --cui-btn-disabled-border-color: #5856d6;
}

.btn-white {
  --cui-btn-color: #fff;
  --cui-btn-bg: #fff;
  --cui-btn-border-color: #dbdfe6;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #fff;
  --cui-btn-hover-border-color: #dbdfe6;
  --cui-btn-focus-shadow-rgb: 113,111,220;
  --cui-btn-active-color: #fff;
  --cui-btn-active-bg: #fff;
  --cui-btn-active-border-color: #fff;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(8,10,12,.125);
  --cui-btn-disabled-color: #fff;
  --cui-btn-disabled-bg: #5856d6;
  --cui-btn-disabled-border-color: #5856d6;
}
.btn-white-round {
  --cui-btn-color: #fff;
  --cui-btn-bg: #fff;
  --cui-btn-border-color: #fff;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #fff;
  --cui-btn-hover-border-color: #fff;
  --cui-btn-focus-shadow-rgb: 113,111,220;
  --cui-btn-active-color: #fff;
  --cui-btn-active-bg: #fff;
  --cui-btn-active-border-color: #fff;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(8,10,12,.125);
  --cui-btn-disabled-color: #fff;
  --cui-btn-disabled-bg: #5856d6;
  --cui-btn-disabled-border-color: #5856d6;
}
.input-gray{
  background-color: #666;
  outline-color : #666;
  --cui-border-color : #d8dbe0;
}

.col-3to2{
  width: 20%
}

.btn-primary-custom {
  --cui-btn-color: #fff;
  --cui-btn-bg: #5856d6;
  --cui-btn-border-color: #5856d6;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #5856d6;
  --cui-btn-hover-border-color: #5856d6;
  --cui-btn-focus-shadow-rgb: 113,111,220;
  --cui-btn-active-color: #5856d6;
  --cui-btn-active-bg: #5856d6;
  --cui-btn-active-border-color: #5856d6;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(8,10,12,.125);
  --cui-btn-disabled-color: #fff;
  --cui-btn-disabled-bg: #5856d6;
  --cui-btn-disabled-border-color: #5856d6;
}
.custom_date_input {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #dbdfe6;
  border-radius: 0.375rem;
  color: rgba(37,43,54,.95);
  color: var(--cui-body-color);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  width: 290%;
}
.not_focus {
  outline:none;
}
.btn-outline-custom {
  --cui-btn-color: #9da5b1;
  --cui-btn-border-color: #9da5b1;
  --cui-btn-hover-color: #080a0c;
  --cui-btn-hover-bg: #b1b7c1;
  --cui-btn-hover-border-color: #a7aeb9;
  --cui-btn-focus-shadow-rgb: 157,165,177;
  --cui-btn-active-color: #080a0c;
  --cui-btn-active-bg: #b1b7c1;
  --cui-btn-active-border-color: #a7aeb9;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(8,10,12,.125);
  --cui-btn-disabled-color: #9da5b1;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
  --cui-btn-padding-x: 0.75rem;
  --cui-btn-padding-y: 0.375rem;
  --cui-btn-font-family: ;
  --cui-btn-font-size: 1rem;
  --cui-btn-font-weight: 400;
  --cui-btn-line-height: 1.5;
  --cui-btn-bg: transparent;
  --cui-btn-border-width: var(--cui-border-width);
  --cui-btn-border-radius: var(--cui-border-radius);
  --cui-btn-box-shadow: inset 0 1px 0 hsla(0,0%,100%,.15),0 1px 1px rgba(8,10,12,.075);
  --cui-btn-disabled-opacity: 0.65;
  --cui-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--cui-btn-focus-shadow-rgb),.5);
  background-color: var(--cui-btn-bg);
  border: var(--cui-btn-border-width) solid var(--cui-btn-border-color);
  border-radius: var(--cui-btn-border-radius);
  color: var(--cui-btn-color);
  cursor: pointer;
  display: inline-block;
  font-family: var(--cui-btn-font-family);
  font-size: var(--cui-btn-font-size);
  font-weight: var(--cui-btn-font-weight);
  line-height: var(--cui-btn-line-height);
  padding: var(--cui-btn-padding-y) var(--cui-btn-padding-x);
  text-align: center;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
}

.input-group-text_order_right {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d8dbe0;
  border-right: #ffffff;
  border-radius: 0.375rem;
  border-radius: var(--cui-border-radius);
  color: #ffffff;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  white-space: nowrap;
}
.input-group-text_order_left {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d8dbe0;
  border-left: #ffffff;
  border-radius: 0.375rem;
  border-radius: var(--cui-border-radius);
  color: #ffffff;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  white-space: nowrap;
}

.input-group-text_order_RL {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d8dbe0;
  border-left: #ffffff;
  border-right: #ffffff;
  border-radius: 0.375rem;
  border-radius: var(--cui-border-radius);
  color: #060707;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  white-space: nowrap;
}
.sidebar_layout_custom {
  --cui-sidebar-bg: #fff;
  --cui-sidebar-nav-link-icon-color: #dab5b5;
  --cui-sidebar-nav-title-color: #060707;
  --cui-sidebar-nav-link-color: #060707;
  --cui-sidebar-nav-link-active-icon-color: #dab5b5;
  --cui-sidebar-nav-link-active-color: #060707;
}